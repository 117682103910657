<template>
  <div class="pa-3">
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        <b>{{ text }}</b>
      </v-snackbar>
    </div>
    <v-text-field v-model="search">
      <template v-slot:label>
        Ürün adı yada kodu yazınız...
        <v-icon style="vertical-align: middle"> mdi-file-find </v-icon>
      </template>
    </v-text-field>
    <v-progress-linear
      v-if="isloading"
      indeterminate
      color="cyan"
    ></v-progress-linear>
    <v-sheet>
      <v-simple-table fixed-header height="70vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Ürün</th>
              <th class="text-left">Marka</th>
              <th v-if="Auth.user.usertype == 'Admin'" class="text-left">
                Stok
              </th>
              <th class="text-left">Liste Fiyatı</th>
              <th class="text-left">İsk</th>
              <th class="text-left">Kdv Hariç</th>
              <th class="text-left">Kdv Dahil</th>
              <th class="text-left">Adet</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in stocklist" :key="s.STOK_KODU">
              <td>
                <span style="font-size: 14px !important"
                  ><b>{{ s.STOK_KODU }}</b> <br />
                  {{ s.STOK_ADI }}</span
                >
              </td>
              <td>
                <div
                  :style="[
                    s.GROUP_KODU == 'DELPHI'
                      ? { color: '#1976d2 ' }
                      : { color: '#f44336' },
                  ]"
                  ><b>{{ s.GROUP_KODU }}</b></div
                >
                <div v-if=" s.KOD_3!=null"
                  style="color: #1f883d;"
                  ><b>{{ s.KOD_3 }}</b></div
                >
              </td>
              <td v-if="Auth.user.usertype == 'Admin'">
                <span>{{ s.STOK_AD }}</span>
              </td>
              <td>
                <span
                  ><b>{{ s.SATIS_FIAT1.toFixed(2) }}₺</b> <br />
                  {{
                    s.DOV_SEMB == "₺"
                      ? ""
                      : s.DOV_SATIS_FIAT.toFixed(2) + s.DOV_SEMB
                  }}</span
                >
              </td>

              <td style="">
                <span v-if="s.ISK1 != 0 && s.ISK1 != null">%{{ s.ISK1 }}</span>
                <span v-if="s.ISK2 != 0 && s.ISK2 != null">+{{ s.ISK2 }} </span>
              </td>
              <td>
                <span
                  ><b
                    >{{
                      (s.NET_FIYAT_KDV / [1 + s.KDV_ORANI / 100]).toFixed(2)
                    }}₺</b
                  >
                  <!-- <br />
                  {{
                    s.DOV_SEMB == "₺"
                      ? ""
                      : (s.NET_FIYAT_KDV_DOV / [1 + s.KDV_ORANI / 100]).toFixed(
                          2
                        ) + s.DOV_SEMB
                  }}--></span
                >
              </td>
              <td>
                <span
                  ><b>{{ s.NET_FIYAT_KDV.toFixed(2) }}₺</b
                  ><!--<br />
                  {{
                    s.DOV_SEMB == "₺"
                      ? ""
                      : s.NET_FIYAT_KDV_DOV.toFixed(2) + s.DOV_SEMB
                  }}--></span
                >
              </td>
              <td>
                <v-text-field
                 
                  dense
                  type="number"
                  hide-details="auto"
                  style="max-width: 30px"
                  value="1"
                  :id="s.STOK_KODU"
                ></v-text-field>
                <!--  <vinput />-->
              </td>
              <td>
                <v-btn
                  v-if="s.STOK_DURUMU == 1"
                  @click="AddCart(s)"
                  color="primary"
                  small
                  outlined
                  >Ekle</v-btn
                >
                <v-btn
                  @click="AddCart(s)"
                  v-else
                  color="red"
                  small
                  outlined
                  >Ön Sipariş Ver</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>
<script>
//import vinput from "./vinput.vue"
export default {
  components: {
    //  vinput
  },
  mounted() {},
  // props: ["cart"],
  data: () => ({
    snackbar: false,
    text: "My timeout is set to 2000.",
    timeout: 2000,
    stocklist: [],
    search: "",
    awaitingSearch: false,
    rules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 3) || "Min 3 characters",
    ],
    isloading: false,
    qty: 1,
  }),
  methods: {
    AddCart(sl) {
      this.snackbar = true;
      this.text = sl.STOK_ADI + " Sepete eklendi !";

      sl.SATIS_AD = parseFloat(document.getElementById(sl.STOK_KODU).value);
      this.$store.commit("addCart", sl);
    },
    TalepEt(elm, sl) {
      // eslint-disable-next-line no-debugger
      debugger;
      const t = elm.path[0];
      t.textContent = "İletiliyor 🎈";
      elm.path[1].disabled = true;
      const productRequestData = {
        CariKodu: this.Auth.user.currentCode,
        CariAdi: this.Auth.user.currentDetail.CARI_ISIM,
        StokKodu: sl.STOK_KODU,
        StokAdi: sl.STOK_ADI,
      };
      const thisvue = this;
      console.log(productRequestData);
      this.$http
        .post(this.$base + "/CustomerRequest/", productRequestData, {
          headers: {
            Authorization: `Bearer ${this.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.snackbar = false;
          t.textContent = "Talebiniz İletildi ✅";

          console.log(response);
        })
        .catch(function (error) {
          thisvue.snackbar = false;
          console.log(error);
        });
    },
    CleanTurhish(value) {
      value = value.replace(/\u00c2/g, "A"); // Â
      value = value.replace(/\u00e2/g, "a"); // â
      value = value.replace(/\u00fb/g, "u"); // û
      value = value.replace(/\u00c7/g, "C"); // Ç
      value = value.replace(/\u00e7/g, "c"); // ç
      value = value.replace(/\u011e/g, "G"); // Ğ
      value = value.replace(/\u011f/g, "g"); // ğ
      value = value.replace(/\u0130/g, "I"); // İ
      value = value.replace(/\u0131/g, "i"); // ı
      value = value.replace(/\u015e/g, "S"); // Ş
      value = value.replace(/\u015f/g, "s"); // ş
      value = value.replace(/\u00d6/g, "O"); // Ö
      value = value.replace(/\u00f6/g, "o"); // ö
      value = value.replace(/\u00dc/g, "U"); // Ü
      value = value.replace(/\u00fc/g, "u"); // ü

      return value;
    },
    searchstock(q) {
      let thisvue = this;
      thisvue.isloading = true;
      this.$http
        .get(this.$base + "/stock/?q=" + this.CleanTurhish(q.toUpperCase()), {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.stocklist = response.data;
          thisvue.isloading = false;
        })
        .catch(function (error) {
          console.log(error);
          thisvue.isloading = false;
        });
    },
  },
  watch: {
    search: function () {
      if (this.search.length < 3) return;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          console.log(this.search);
          this.searchstock(this.search);
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
};
</script>
<style >
table * {
  font-size: 14px !important;
}
td {
  padding: 0px 5px !important;
}
</style>