<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-tabs centered class="ml-n9" color="grey darken-1">
        <v-tab>
          <v-img :src="require('../assets/logo.png')" contain height="59" />
        </v-tab>
        <v-tab v-for="link in links" :key="link">
          {{ link }}
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="0" sm="4" />
          <v-col cols="12" sm="4">
            <v-sheet min-height="30vh" rounded="lg">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form
                  @submit.prevent="submit"
                  style="padding: 20px"
                  class="mx-auto"
                  max-width="200"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    :rules="{
                      required: true,
                    }"
                  >
                    <v-text-field
                      v-model="password"
                      :error-messages="errors"
                      label="Şifre"
                      type="password"
                      required
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="checkbox">
                    <v-checkbox
                      v-model="checkbox"
                      :error-messages="errors"
                      value="1"
                      label="Beni hatırla"
                      type="checkbox"
                    ></v-checkbox>
                  </validation-provider>

                  <v-btn class="mr-4" type="submit" :disabled="invalid">
                    Giriş
                  </v-btn>
                </form>
              </validation-observer>
              <v-progress-linear
                v-if="isloading"
                indeterminate
                color="cyan"
              ></v-progress-linear>
            </v-sheet>
          </v-col>
          <v-col cols="0" sm="4" />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} zorunlu alan",
});

extend("email", {
  ...email,
  message: "Geçerli mail adresi giriniz",
});
export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    links: [],
    password: "",
    email: "",
    checkbox: false,
    logindata: { auth: null, user: null },
    isloading: false,
  }),

  methods: {
    submit() {
      (this.isloading = true), this.$refs.observer.validate();
      let thisvue = this;
      this.$http
        .post(this.$base + "/auth/login", {
          Email: this.email,
          password: this.password,
        })
        .then(function (response) {
          thisvue.logindata.auth = response.data;
          thisvue = thisvue.getUser(response.data);
        })
        .catch(function (error) {
          console.log(error);
          thisvue.isloading = false;
        });
    },
    getUser(auth) {
      let thisvue = this;
      this.$http
        .get(this.$base + "/auth/user", {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.logindata.user = response.data;
          thisvue.logindata.user.currentDetail = JSON.parse(
            response.data.currentDetail
          );
          thisvue.$store.commit("setAuth", thisvue.logindata);
          thisvue.isloading = false;
        })
        .catch(function (error) {
          console.log(error);
          thisvue.isloading = false;
        });
    },
  },
};
</script>