import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import { store } from "./store/store"


Vue.prototype.$http = axios
Vue.prototype.$base = "https://b2b.ozmalatyalilar.com.tr/api";
//Vue.prototype.$base="https://localhost:44355/api";

 

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
