import { render, staticRenderFns } from "./Current.vue?vue&type=template&id=584d4bc3&scoped=true&"
import script from "./Current.vue?vue&type=script&lang=js&"
export * from "./Current.vue?vue&type=script&lang=js&"
import style0 from "./Current.vue?vue&type=style&index=0&id=584d4bc3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584d4bc3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VSimpleTable})
