<template>
  <div>
    <v-simple-table dense fixed-header height="60vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Tarih</th>
            <th class="text-left">Belge No</th>
            <th class="text-left">ACIKLAMA</th>            
            <th class="text-left">Borç</th>
            <th class="text-left">Alacak</th>            
            <th class="text-left">Borç Bakiye</th>
            <th class="text-left">Alacak Bakiye</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in CurrentData.CariHareket" :key="item.FIS_NO">
            <td>{{ new Date(item.TARIH).toLocaleDateString("tr-TR") }}</td>
            <td>
              {{ item.FIS_NO }}
            </td>
            <td>{{ item.ACIKLAMA }}</td>
            <td>{{ item.BORC }}</td>
            <td>{{ item.ALACAK }}</td>
            <td style="color:red">{{ item.BORC_BAKIYE }}</td>
            <td style="color:green">{{ item.ALACAK_BAKIYE }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div height="60vh" >
      <v-card class="pa-2" style=" float: right;"
        ><b>Toplam :</b>
        <span style="color: red"
          >{{ CurrentData.Toplam }}₺</span
        ></v-card
      >
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    CurrentData: {
      CariHareket: [],
    },
  }),
  mounted() {
    this.GetCurrDetail();
  },
  methods: {
    GetCurrDetail() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/Current/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.CurrentData = response.data;
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
};
</script>
<style  scoped>
* {
  box-shadow: none !important;
}
</style>