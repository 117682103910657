<template>
  <div>
    <v-card v-if="saleresult != null" outlined>
      <v-container class="grey lighten-5" style="max-with">
        <v-row ma align="center" justify="center" no-gutters>
          <v-col class="mb-4"> </v-col>
          <v-col class="mb-4">
            <v-card class="pa-4" outlined tile>
              <iframe
                v-if="saleresult.threeDUrl != null"
                style="min-height: 550px; min-width: 550px; border: 0px"
                :src="saleresult.threeDUrl"
              ></iframe>
              <span>{{ saleresult.internalMessage }}</span>
            </v-card>
          </v-col>
          <v-col class="mb-4"> </v-col>
        </v-row>
        <v-row ma align="center" justify="center" no-gutters>
          <v-col class="mb-4"> </v-col>
          <v-col class="mb-4">
            <v-btn class="ma-2" block color="yellow" @click="CleanForm">
              Ödeme bilgilerini düzenle ve tekar dene
            </v-btn>
          </v-col>
          <v-col class="mb-4"> </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-else outlined>
      <v-container class="grey lighten-5" style="max-with">
        <v-row ma align="center" justify="center" no-gutters>
          <v-col class="mb-4" v-if="Gw()"> </v-col>
          <v-col class="mb-4">
            <v-card class="pa-4" outlined tile>
              <v-text-field
                autocomplete="new-password"
                v-model="tutar"
                type="tel"
                label="Ödemek İstediğiniz Tutarı Yazının"
              ></v-text-field>
              <v-text-field
                autocomplete="new-password"
                v-model="numara"
                 type="tel"
                label="Kredi Kartınızın Üzerindeki 16 Haneyi Yazın"
                v-mask="'#### #### #### ####'"
              ></v-text-field>

              <v-text-field
                autocomplete="new-password"
                v-model="adsoyad"
                label="Kart Üzerindeki Ad/Soyad"
              ></v-text-field>
              <v-select
                autocomplete="new-password"
                v-model="say"
                :items="ay"
                label="Ay"
                required
                width="200"
              ></v-select>
              <v-select
                autocomplete="new-password"
                v-model="syil"
                :items="yil"
                label="Yıl"
                required
                width="200"
              ></v-select>

              <v-text-field
                autocomplete="new-password"
                v-model="cvv"
                label="CVV"
              ></v-text-field>
              <v-radio-group
               v-if="banka.comissionRates.length > 0"
               v-model="rVPosId">
                <v-radio
                  v-for="(item, i) in comissions"
                  :key="i"
                  :label="item.vPosName"
                  :value="item.vPosId"
                ></v-radio>
              </v-radio-group>
              <v-select
                v-if="banka.comissionRates.length > 0"
                autocomplete="new-password"
                v-model="staksit"
                :items="banka.comissionRates"
                item-value="instalment"
                item-text="note"
                label="Taksit"
                required
                width="200"
              ></v-select>
            </v-card>
          </v-col>
          <v-col class="mb-2" v-if="Gw()">
            <v-row justify="center">
              <v-expansion-panels accordion style="max-width: 250px">
                <v-expansion-panel v-for="(item, i) in comissions" :key="i">
                  <v-expansion-panel-header>{{
                    item.vPosName
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Taksit</th>
                            <th class="text-left">Oran</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(com, t) in item.comissionRates" :key="t">
                            <td v-if="com.plusInstalment == 0">
                              {{ com.instalment }}
                            </td>
                            <td v-else>
                              {{ com.instalment }} + {{ com.plusInstalment }}
                            </td>
                            <td>{{ com.commRate }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
        </v-row>
        <v-row ma align="center" justify="center" no-gutters>
          <v-col class="mb-4"> </v-col>
          <v-col class="mb-4">
            <v-btn
              class="ma-2"
              block
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="SubmitDeposit"
            >
              Ödeme Yap
            </v-btn>
          </v-col>
          <v-col class="mb-4"> </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  data: () => ({
    loading: false,
    saleresult: null,
    tutar: 0,
    numara: "",
    cvv: "",
    say: "",
    syil: "",
    staksit: "",
    adsoyad: "",
    awaiting: false,
    ay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    yil: [
      2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
      2033, 2034, 2035,
    ],
    banka: {
      comissionRates: [],
      vPosId: 0,
      vPosName: "|",
      vM: "",
    },
    comissions: [],
    rVPosId: 0,
  }),
  components: {},
  mounted() {
    this.GetBankComissions();
  },
  methods: {
    Gw() {
      return window.innerWidth > 450;
    },
    GetBank() {
      let thisvue = this;
      let bin = thisvue.numara.substring(0, 7).replaceAll(" ", "");
      this.$http
        .get(this.$base + "/PosCollection/" + bin, {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.banka = response.data;
          thisvue.rVPosId = thisvue.banka.vPosId;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetBankComissions() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/PublicPosCollection/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.comissions = response.data;
          for (let i = 0; i < thisvue.comissions.length; i++) {
            if(thisvue.comissions[i].vPosId==3){
              thisvue.comissions[i].vPosName=thisvue.comissions[i].vPosName+" (Varsayılan)"
            }
            const comissionRates = thisvue.comissions[i].comissionRates;
            for (let k = 0; k < comissionRates.length; k++) {
              const com = comissionRates[k];
               if (com.instalment == 1)
                com.note = "Tek Çekim";
             else if (com.plusInstalment == 0)
                com.note = com.instalment + " Taksit";
              else com.note = `${com.instalment}+${com.plusInstalment} Taksit`;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    CleanForm() {
      this.saleresult = null;
      this.numara = null;
      this.adsoyad = null;
      this.say = null;
      this.syil = null;
      this.staksit = null;
      this.cvv = null;
      this.loading = false;
    },
    SubmitDeposit() {
      this.loading = "loading";
      let thisvue = this;
      var dump = {
        prams: {
          Amount: parseFloat(this.tutar),
          VirtualPosId: parseInt(this.banka.vPosId),
          Installment: parseInt(this.staksit),
        },
        CreditCard: {
          CardNumber: this.numara.replaceAll(" ", ""),
          CardHolderName: this.adsoyad,
          ExpYear: this.syil,
          ExpMonth: this.say,
          CvcNumber: this.cvv,
        },
      };
      console.log(JSON.stringify(dump));

      this.$http
        .post(this.$base + "/PosCollection/", dump, {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.saleresult = response.data;
          console.log(response.data);
        })
        .catch(function (error) {
          thisvue.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    banka: function () {
      this.banka.vM = this.banka.vPosName.split("|")[1];
    },
    numara: function () {
      if (this.numara.length < 7) return;
      if (!this.awaiting) {
        setTimeout(() => {
          console.log(this.numara);
          this.GetBank();
          this.awaiting = false;
        }, 1000); // 1 sec delay
      }
      this.awaiting = true;
    },
    rVPosId: function () {
      var item = this.comissions.filter((x) => x.vPosId == this.rVPosId)[0];
      this.banka = {
        comissionRates: item.comissionRates,
        vPosId: item.vPosId,
        vPosName: item.vPosName,
        defaultPosComissionRate: item.defaultPosComissionRate,
        isDefaultPos: item.isDefaultPos,
      };
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
};
</script>
<style >
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>