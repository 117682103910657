<template>
  <div>
    <v-text-field
      style="padding: 10px"
      v-model="filter"
      dense
      label="Arama"
    ></v-text-field>
    <v-simple-table dense fixed-header height="60vh">
      <template v-slot:default>
        <thead>
          <tr>
            <!--
         "id": 1,
        "currentCode": "00409",
        "name": "Oğuzhan",
        "surname": "OSKAY",
        "email": "o@o.com",
        "password": "dell1501",
        "usertype": "Admin",
        "refreshToken": "zSGwtCKPNgprBj32Ev8rkjUfvWznWEkuuXl5mJ9EIy8=",
        "refreshTokenEndDate": "2021-03-11T00:03:00"
        -->
            <th class="text-left">Cari Kodu</th>
            <th class="text-left">Adı</th>
            <th class="text-left">Soyadı</th>
            <th class="text-left">Email</th>
            <th class="text-left">Şifre</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredList" :key="item.id">
            <td>{{ item.currentCode }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.surname }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.password }}</td>
            <td>
              <v-btn @click="login(item)" color="orange" small outlined
                >Giriş</v-btn
              >
              <v-btn @click="UserEdit(item.id)" color="primary" small outlined
                >Düzenle</v-btn
              >
              <v-btn @click="DeleteUser(item.id)" color="red" small outlined
                >Sil</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  mounted() {
    this.GetuserList();
  },
  data: () => ({
    filter: "",
    filteredList: [],
    logindata: { auth: null, user: { currentDetail: null } },
  }),
  methods: {
    DeleteUser(id) {
      let thisvue = this;
      this.$http
        .delete(this.$base + "/User/" + id, {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.GetuserList();
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetuserList() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/User/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.filteredList = response.data;
          thisvue.$store.commit("setUserList", response.data);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    UserEdit(userId) {
      this.$store.commit("SelectUserDetailId", userId);
    },
    login(item) {
      let thisvue = this;
      this.$http
        .post(this.$base + "/auth/login", {
          Email: item.email,
          password: item.password,
        })
        .then(function (response) {
          thisvue.logindata.auth = response.data;
          thisvue = thisvue.getUser(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUser(auth) {
      let thisvue = this;
      this.$http
        .get(this.$base + "/auth/user", {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.logindata.user = response.data;
          thisvue.logindata.user.currentDetail = JSON.parse(
            response.data.currentDetail
          );
          thisvue.$store.commit("setAuthByAdmin", thisvue.logindata);
          location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
    UserList() {
      return this.$store.getters.getuserList;
    },
  },
  watch: {
    filter: function (val) {
      if (val == "") {
        this.filteredList = this.UserList;
      } else {
        this.filteredList = this.UserList.filter(
          (x) =>
            x.name.includes(val) ||
            x.surname.includes(val) ||
            x.email.includes(val)
        );
      }
    },
  },
};
</script>