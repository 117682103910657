<template>
  <div>
    <v-card>
      <v-tabs background-color="primary accent-4" centered dark icons-and-text>
        <v-tab @click="selectedTab = 'invoice'">
          Son 10 Fatura
          <v-icon>mdi-file</v-icon>
        </v-tab>

        <v-tab @click="selectedTab = 'order'">
          Son 10 Sipariş
          <v-icon>mdi-shopping</v-icon>
        </v-tab>
      </v-tabs>
      <v-card flat v-if="selectedTab == 'invoice'">
        <v-card-text><h3>Son 10 Fatura</h3></v-card-text>
        <v-divider></v-divider>
        <v-expansion-panels>
          <v-expansion-panel v-for="i in Invoices" :key="i.FATIRS_NO">
            <v-expansion-panel-header>
              
                <v-card class="pa-2">
                  <div elevation="2" color="primary" small>
                   <span class="f-tarih">Tarih : {{ new Date(i.TARIH).toLocaleDateString("tr-TR") }} </span>
                   <span class="f-btn">Detaylar</span>
                   <span class="f-no">F.No: {{ i.FATIRS_NO }} </span>
                   
                   </div
                  >
                </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Adı</th>
                      <th class="text-left">Kodu</th>
                      <th class="text-left">Adet</th>
                      <th class="text-left">Bürüt Fiyat</th>
                      <th class="text-left">İsk1</th>
                      <th class="text-left">İsk2</th>
                      <th class="text-left">KDV</th>
                      <th class="text-left">Net Fİyat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="k in i.KALEMLER" :key="k.STOK_KODU">
                      <td>{{ k.STOK_ADI }}</td>
                      <td>{{ k.STOK_KODU }}</td>
                      <td>{{ k.STHAR_GCMIK }}</td>
                      <td>{{ k.STHAR_BF }}₺</td>
                      <td>{{ k.STHAR_SATISK }}</td>
                      <td>{{ k.STHAR_SATISK2 }}</td>
                      <td>{{ k.STHAR_KDV }}</td>
                      <td>{{ k.STHAR_NF }}₺</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card class="pa-2"><b>KDV :</b> {{ i.KDV }}</v-card>
              <v-card class="pa-2">
                <b>İskonto :</b>{{ i.SAT_ISKT.toFixed(2) }}₺</v-card
              >

              <v-card class="pa-2"
                ><b>Bürüt Tutar :</b> {{ i.BRUTTUTAR.toFixed(2) }}₺</v-card
              >
              <v-card class="pa-2"
                ><b>Genel Toplam</b> {{ i.GENELTOPLAM.toFixed(2) }}₺</v-card
              >
              <v-card class="pa-2">
                <v-btn elevation="2" color="yellow" small
                  >E-Fatura Görüntüle</v-btn
                >
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card flat v-if="selectedTab == 'order'">
        <v-card-text><h3>Son 10 Sipariş</h3></v-card-text>
        <v-divider></v-divider>
        <v-expansion-panels>
          <v-expansion-panel v-for="i in Orders" :key="i.FATIRS_NO">
            <v-expansion-panel-header>
                 <v-card class="pa-2">
                  <div elevation="2" color="primary" small>
                   <span class="f-tarih">Tarih : {{ new Date(i.TARIH).toLocaleDateString("tr-TR") }} </span>
                   <span class="f-btn">Detaylar</span>
                   <span class="f-no">F.No: {{ i.FATIRS_NO }} </span>
                   
                   </div
                  >
                </v-card>
              
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Adı</th>
                      <th class="text-left">Kodu</th>
                      <th class="text-left">Adet</th>
                      <th class="text-left">Bürüt Fiyat</th>
                      <th class="text-left">İsk1</th>
                      <th class="text-left">İsk2</th>
                      <th class="text-left">KDV</th>
                      <th class="text-left">Net Fİyat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="k in i.KALEMLER" :key="k.STOK_KODU">
                      <td>{{ k.STOK_ADI }}</td>
                      <td>{{ k.STOK_KODU }}</td>
                      <td>{{ k.STHAR_GCMIK }}</td>
                      <td>{{ k.STHAR_BF.toFixed(2) }}₺</td>
                      <td>{{ k.STHAR_SATISK }}</td>
                      <td>{{ k.STHAR_SATISK2 }}</td>
                      <td>{{ k.STHAR_KDV }}</td>
                      <td>{{ k.STHAR_NF.toFixed(2) }}₺</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card class="pa-2"><b>KDV :</b> {{ i.KDV }}</v-card>
              <v-card class="pa-2">
                <b>İskonto :</b>{{ i.SAT_ISKT.toFixed(2) }}₺</v-card
              >

              <v-card class="pa-2"
                ><b>Bürüt Tutar :</b> {{ i.BRUTTUTAR.toFixed(2) }}₺</v-card
              >
              <v-card class="pa-2"
                ><b>Genel Toplam</b> {{ i.GENELTOPLAM.toFixed(2) }}₺</v-card
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return { Invoices: [], Orders: [], selectedTab: "invoice" };
  },
  mounted() {
    console.log("old orders mounted...");
    this.GetInvoices();
    this.GetOrders();
  },
  methods: {
    GetInvoices() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/Invoice/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.Invoices = response.data;
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetOrders() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/Order/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.Orders = response.data;
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
};
</script>
<style  scoped>
* {
  box-shadow: none !important;
}
.f-tarih{display: block; float: left;font-size: 12px;}
.f-no{display: block;font-size: 12px; float: left; border-left: 1px solid rgb(167, 167, 167); margin: 0 0 0 5px; padding: 0 0 0 5px;}
.f-btn{display: block; float: right; ;padding:2px; margin: 0 5px; font-size: 11px;}
</style>