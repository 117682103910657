import Vue from "vue";
import Vuex from "vuex"
Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        cart: [],
        auth: null,
        UserDetailId: 0,
        UserList: [],
        issublogin: false
    },
    getters: {
        getissublogin() {
            return localStorage.getItem("authold") != null;
        },
        getuserList(state) {

            return state.UserList;
        },
        getCart(state) {
            state.cart.forEach(function (item) {
                item.NET_TUTAR_KDV = parseFloat(item.NET_FIYAT_KDV * item.SATIS_AD).toFixed(2);
                item.NET_TUTAR_KDV_DOV = parseFloat(item.NET_FIYAT_KDV_DOV * item.SATIS_AD).toFixed(2);
            });
            return state.cart;
        },
        getCartTotal(state) {
            var TOPLAM_TUTAR_TL = 0;
            var TOPLAM_TUTAR_DOV = 0;
            var DOV_SEMB = 0;
            var TOPLAM_ADET = 0;
            state.cart.forEach(function (item) {
                TOPLAM_TUTAR_TL += parseFloat(item.NET_FIYAT_KDV) * parseInt(item.SATIS_AD);
                TOPLAM_ADET += parseInt(item.SATIS_AD)
                TOPLAM_TUTAR_DOV = parseFloat(item.NET_FIYAT_KDV_DOV) * parseInt(item.SATIS_AD);
                DOV_SEMB = item.DOV_SEMB;
            });
            return { TOPLAM_TUTAR_TL, TOPLAM_ADET, TOPLAM_TUTAR_DOV, DOV_SEMB };
        },

        getAuth(state) {
            return state.auth;
        },
        getUserDetailId(state) {
            return state.UserDetailId;
        }
    }, mutations: {
        addCart(state, value) {
            // eslint-disable-next-line no-debugger
            debugger;
            console.log(value)
            value.info = false;
            var index = state.cart.findIndex(
                (e) => e.STOK_KODU === value.STOK_KODU
            );
            if (index != -1) {
                var line = state.cart.splice(index, 1)[0];
                if (line != undefined) {
                    line.SATIS_AD += value.SATIS_AD;
                    value = line;
                }
            }
            if (value.STOK_AD < value.SATIS_AD) {
                value.info = true;
            }
            if (value.STOK_AD < value.SATIS_AD && value.STOK_AD != 0) {
                value.SATIS_AD = value.STOK_AD;
            }
            state.cart.unshift(value);
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        recoverCart(state, value) {
            state.cart = value;
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        removeCart(state, value) {
            var index = state.cart.findIndex(
                (e) => e.STOK_KODU === value
            );
            if (index != -1) {
                state.cart.splice(index, 1)[0];
            }
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        fullRemoveCart(state) {
            state.cart = [];
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        updateCartItem(state, value) {
            var index = state.cart.findIndex(
                (e) => e.STOK_KODU === value.STOK_KODU
            );
            if (index != -1) {
                var line = state.cart.splice(index, 1)[0];
                if (line != undefined) {
                    line.SATIS_AD = value.SATIS_AD;
                    value = line;
                }
            }
            state.cart.unshift(value);
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        setAuth(state, value) {
            state.auth = value;
            localStorage.setItem("auth", JSON.stringify(state.auth));
        },
        SelectUserDetailId(state, value) {
            state.UserDetailId = value;
        },
        setAuthByAdmin(state, value) {
            if (localStorage.getItem("authold") == null)
                localStorage.setItem("authold", localStorage.getItem("auth"));
            localStorage.setItem("auth", JSON.stringify(value));
            state.auth = value;
        },
        setAuthLogOutByAdmin(state) {
            localStorage.setItem("auth", localStorage.getItem("authold"));
            localStorage.removeItem("authold");
            state.auth = JSON.parse(localStorage.getItem("auth"));
        },
        SelectUserDetailIdByAdmin(state, value) {
            state.UserDetailId = value;
        },
        setUserList(state, value) {
            state.UserList = value;
        }
    }
});