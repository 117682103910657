<template>
  <v-app>   
      <Login v-if="Auth == null" />
      <Main v-else />   
  </v-app>
</template>

<script>
import Main from "./components/Main";
import Login from "./components/Login";

export default {
  name: "App",
  components: {
    Main,
    Login,
  },

  mounted() {
    var lauth = JSON.parse(localStorage.getItem("auth"));
    if (lauth != null && lauth != undefined) {
      if (new Date(lauth.auth.expiration) > new Date())
        this.$store.commit("setAuth", lauth);
    }
    var lcart = JSON.parse(localStorage.getItem("cart"));
    if (lcart != null && lcart.length > 0)
      this.$store.commit("recoverCart", lcart);
  },
  data: () => ({
    authresult: {
      auth: { accessToken: null },
    },
  }),
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
};
</script>
