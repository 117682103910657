<template>
  <div>
    <v-text-field dense label="Arama"></v-text-field>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  data: () => ({
    creditCardForm: {
      holder: "Orkun ÇAKILKAYA",
      number: 9792030000000000,
      month: 12,
      year: 23,
      cvv: 456,
    },
  }),
};
</script>