<template>
  <v-app id="inspire">
    <nav class="navbar">
      <span
        class="navbar-toggle"
        id="js-navbar-toggle"
        @click="menuactive = !menuactive"
      >
        <v-icon large>mdi-menu</v-icon>
      </span>
      <a href="#" class="logo col-sm-3 col-9 py-0">
        <img :src="require('../assets/logo.png')" contain class="img-res"
      /></a>
      <ul class="main-nav" id="js-menu" :class="menuactive ? 'active' : ''">
        <li v-for="link in links" :key="link.key">
          <a @click="selectedpage = link.key" class="nav-links">{{
            link.title
          }}</a>
        </li>
        <li>
          <a @click="LogOff" class="nav-links">Çıkış</a>
        </li>
      </ul>
      <div class="bilgi-cari">
        <div class="doviz">
          Dolar: {{ Dolar }} ₺ Euro: {{ Euro }} ₺
          <span v-if="Auth.user.detail.Toplam != undefined" class="bakiye">
            Bakiye:{{ Auth.user.detail.Toplam }} ₺</span
          >
        </div>
        {{ Auth.user.currentDetail.CARI_ISIM }}
        ({{ Auth.user.currentDetail.CARI_KOD }})
        <a @click="LogOutCurrByAdmin" class="blink" v-if="issublogin"
          >Cariden Çıkış Yap</a
        >
      </div>
    </nav>
    <v-main class="grey lighten-4">
      <v-container>
        <v-row>
          <v-col cols="12" :sm="LeftPanelSM() ? 12 : 8">
            <v-sheet min-height="70vh" rounded="lg">
              <Search v-if="selectedpage == 'Search'" />
              <History v-else-if="selectedpage == 'History'" />
              <Current v-else-if="selectedpage == 'Current'" />
              <PosCollection v-else-if="selectedpage == 'PosCollection'" />
              <OrderN v-else-if="selectedpage == 'OrderN'" />
              <User
                v-else-if="
                  selectedpage == 'Users' && Auth.user.usertype == 'Admin'
                "
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4" v-if="!LeftPanelSM()">
            <v-sheet min-height="70vh" rounded="lg">
              <UserDetail
                v-if="selectedpage == 'Users' && Auth.user.usertype == 'Admin'"
              />
              <Cart v-else />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Search from "./Search";
import History from "./History";
import Cart from "./Cart";
import Current from "./Current";
import PosCollection from "./PosCollection";
import User from "./User";
import UserDetail from "./UserDetail";
import OrderN from "./OrderN";

export default {
  name: "Main",
  components: {
    Search,
    History,
    Cart,
    Current,
    PosCollection,
    User,
    UserDetail,
    OrderN,
  },
  mounted() {
    this.getUser();
    this.getCurrency();

    if (this.Auth.user.usertype == "Admin") {
      this.links.push({ key: "Users", title: "Kullanıcılar" });
    }
  },
  data: () => ({
    // selectedpage: "OrderN",
    selectedpage: "Search",
    links: [
      { key: "Search", title: "Ürün Ara" },
      { key: "History", title: "Siparişlerim" },
      { key: "PosCollection", title: "Ödeme" },
      { key: "Current", title: "Cari Hesap" },
    ],
    Dolar: 0,
    Euro: 0,
    menuactive: false,
    // cart: JSON.parse(localStorage.getItem("cart")),
  }),
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
    issublogin() {
      return this.$store.getters.getissublogin;
    },
  },
  methods: {
    LogOutCurrByAdmin() {
      this.$store.commit("setAuthLogOutByAdmin");
      location.reload();
    },
    LeftPanelSM() {
      return (
        this.selectedpage == "PosCollection" || this.selectedpage == "OldOrder"
      );
    },
    LogOff() {
      this.$store.commit("setAuth", null);
    },
    getCurrency() {
      var auth = this.$store.getters.getAuth;
      let thisvue = this;
      this.$http
        .get(this.$base + "/ForeignCurrency", {
          headers: {
            Authorization: `Bearer ${auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.Dolar = response.data[0].DOV_SATIS;
          thisvue.Euro = response.data[1].DOV_SATIS;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUser() {
      var auth = this.$store.getters.getAuth;
      let thisvue = this;
      this.$http
        .get(this.$base + "/auth/user", {
          headers: {
            Authorization: `Bearer ${auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          auth.user = response.data;
          auth.user.currentDetail = JSON.parse(response.data.currentDetail);
          auth.user.detail = JSON.parse(response.data.current);
          thisvue.$store.commit("setAuth", auth);
          thisvue.isloading = false;
        })
        .catch(function (error) {
          console.log(error);
          thisvue.isloading = false;
        });
    },
  },
};
</script>
<style scoped>
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  background: white;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.navbar {
  font-size: 18px;
  background-image: linear-gradient(348deg, #1976d2 0%, #ffffff 100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.main-nav {
  list-style-type: none;
  display: none;
}

.nav-links {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: rgba(14, 0, 75, 0.7);
  cursor: pointer;
}
.logo {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: inline-block;
  padding-top: 5px !important;
}

.navbar-toggle {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  z-index: 99;
}

.active {
  display: block;
}
.img-res {
  width: 100%;
}
.bilgi-cari {
  font-size: 11px;
  color: #fff;
  padding: 0 5px;
}
.doviz {
  background: #ffffff8a;
  color: #363636;
  padding: 3px;
  font-size: 14px;
  font-weight: 500;
}
.bakiye {
  font-weight: 900;
  display: block;
  float: right;
}

@media screen and (min-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 70px;
    align-items: center;
  }

  .main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-nav li {
    margin: 0;
  }

  .nav-links {
    margin-left: 40px;
  }

  .logo {
    margin-top: 0;
    padding-top: 5px !important;
  }

  .navbar-toggle {
    display: none;
  }

  .logo:hover,
  .nav-links:hover {
    color: rgba(255, 255, 255, 1);
  }
}
</style>
