<template>
  <div class="pa-3">
    <div v-if="ordernumber == null && loading == false">
      <v-banner single-line>
        Alışveriş Sepeti
        <template v-slot:actions>
          <v-btn
            text
            color="red"
            x-small
            outlined
            small
            @click="FullRemoveCart"
          >
            Sepeti Boşalt
          </v-btn>
        </template>
      </v-banner>

      <v-simple-table fixed-header height="69vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th wihit class="text-left">Ürün</th>
              <th class="text-left">Fiyat</th>
              <th class="text-left">Adet</th>
              <th class="text-left">Tutar</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in Cart" :key="s.STOK_KODU">
              <td>
                <span style="font-size: 14px !important"
                  ><b>{{ s.STOK_KODU }}</b> {{ s.GROUP_KODU }}<br />
                  {{ s.STOK_ADI }}</span
                >
                <v-alert
                  v-if="s.info && s.STOK_AD == 0"
                  dense
                  border="left"
                  type="error"
                  style="padding: 5px"
                  >Ön Sipariş
                </v-alert>
                <v-alert
                  v-if="s.info && s.STOK_AD != 0"
                  dense
                  border="left"
                  type="warning"
                  style="padding: 5px"
                  >En fazla <strong>{{ s.SATIS_AD }}</strong> adet sipariş
                  verilebilir.
                </v-alert>
              </td>
              <td>
                <span
                  ><b>{{ s.NET_FIYAT_KDV.toFixed(2) }}₺</b
                  ><!-- <br />
                  {{
                    s.DOV_SEMB == "₺" ? "" : s.NET_FIYAT_KDV_DOV.toFixed(2) + s.DOV_SEMB
                  }}--></span
                >
              </td>
              <td>
                {{ s.SATIS_AD }}
              </td>
              <td>
                <span
                  ><b>{{ s.NET_TUTAR_KDV }}₺</b>
                  <!--<br />
                  {{
                    s.DOV_SEMB == "₺" ? "" : s.NET_TUTAR_KDV_DOV + s.DOV_SEMB
                  }}--></span
                >
              </td>
              <td>
                <v-btn
                  style="min-width: 10px; padding: 0 3px"
                  @click="RemoveCart(s)"
                  color="red"
                  x-small
                  outlined
                  >X</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!--     <v-btn @click.stop="dialog = true" block color="primary" elevation="2"
        >Sepeti Onayla ({{ CartTotal.TOPLAM_TUTAR_TL.toFixed(2) }}₺)</v-btn
      >

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline"> Sipariş Onayı </v-card-title>

            <v-card-text>
              Toplam tutarı :
              <b>{{ CartTotal.TOPLAM_TUTAR_TL }}₺</b> olan
              <b> {{ CartTotal.TOPLAM_ADET }}</b> parça siparişinizi onaylıyor
              musunuz?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialog = false">
                Geri Dön
              </v-btn>

              <v-btn color="blue darken-1" text @click="OrderSubmit()">
                Onaylıyorum
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>-->
      <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="primary" dark v-bind="attrs" v-on="on">
              Sepeti Onayla ({{ CartTotal.TOPLAM_TUTAR_TL.toFixed(2) }}₺)
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Kargo Firması Seçin</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 250px; padding: 0 24px 0px">
              <v-radio-group v-model="kargo" column>
                <v-radio label="Yurtiçi Kargo" value="Yurtiçi Kargo"></v-radio>
                <v-radio label="Aras Kargo" value="Aras Kargo"></v-radio>
                <v-radio label="MNG Kargo" value="MNG Kargo"></v-radio>
                <v-radio label="Sürat Kargo" value="Sürat Kargo"></v-radio>
                <v-radio label="Otobüs" value="Otobüs"></v-radio>
                <v-radio label="Kurye" value="Kurye"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-container fluid>
              <v-textarea
                filled
                v-model="not"
                label="Sipariş Notu"
                auto-grow
                value=""
              ></v-textarea>
            </v-container>
            <v-card-actions>
              <v-btn color="red darken-1" outlined text @click="dialog = false">
                Geri Dön
              </v-btn>
              <v-btn color="blue darken-1" outlined text @click="OrderSubmit()">
                Onaylıyorum
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div v-if="ordernumber == null && loading == true">
      <v-container style="height: 400px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center" cols="12">
            Siparişiniz Aktarılıyor...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div center v-if="ordernumber != null && loading == false">
      <v-icon size="100" color="green"> mdi-check-circle-outline </v-icon>
      <div :class="[`text-h3`, `mb-1`]" class="transition-swing">
        Siparişiniz Başarıyla Alınmıştır.
      </div>
      <div :class="[`text-h5`, active && `mb-1`]" class="transition-swing">
        Sipariş Numaranız : {{ ordernumber }}
      </div>
      <v-btn depressed color="primary" @click="ordernumber = null">
        Yeni Sipariş Ver
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "Cart",
  data() {
    return {
      dialog: false,
      kargo: "",
      loading: false,
      ordernumber: null,
      not: "",
    };
  },
  computed: {
    Cart() {
      return this.$store.getters.getCart;
    },
    CartTotal() {
      return this.$store.getters.getCartTotal;
    },
    Auth() {
      return this.$store.getters.getAuth;
    },
  },
  methods: {
    RemoveCart(sl) {
      this.$store.commit("removeCart", sl.STOK_KODU);
    },
    FullRemoveCart() {
      this.$store.commit("fullRemoveCart");
    },
    OrderSubmit() {
      if (this.$store.getters.getCart.length <= 0) {
        alert("Boş sepet ile sipariş veremezsiniz !");
        return;
      }
      let thisvue = this;
      thisvue.dialog = false;
      thisvue.loading = true;
      var orderdata = {
        ustBilgi: {
          plasiyerKodu: "0",
          cariKodu: thisvue.Auth.user.currentCode,
          subeKodu: 200,
          kargo: thisvue.kargo,
          not: thisvue.not,
        },
        line: [],
      };
      thisvue.Cart.forEach((item) => {
        orderdata.line.push({
          STOK_KODU: item.STOK_KODU,
          ADET: item.SATIS_AD,
        });
      });
      //   thisvue.isloading = true;
      this.$http
        .post(this.$base + "/order/", orderdata, {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.loading = false;
          thisvue.ordernumber = response.data.ReturnMessage;
          thisvue.FullRemoveCart();
          console.log(response);
        })
        .catch(function (error) {
          thisvue.loading = false;
          console.log(error);
        });
    },
  },
};
</script>