<template>
  <div>
    <div style="padding: 10px">
      <v-text-field
        v-model="userDetail.currentCode"
        label="Cari Kodu"
        required
      ></v-text-field>
      <v-text-field
        v-model="userDetail.name"
        label="Adı"
        required
      ></v-text-field>
      <v-text-field
        v-model="userDetail.surname"
        label="Soyadı"
        required
      ></v-text-field>
      <v-text-field
        v-model="userDetail.email"
        label="E-Mail"
        required
      ></v-text-field>
      <v-text-field
        v-model="userDetail.password"
        label="Şifre"
        required
      ></v-text-field>
      <v-btn outlined class="mr-4" color="green" @click="Saveuser"
        >Kaydet</v-btn
      >
      <v-btn outlined class="mr-4" @click="NewUser" color="primary">Yeni</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  data: () => ({
    userDetail: {
      id: 0,
      currentCode: "",
      name: "",
      surname: "",
      email: "",
      password: "",
      usertype: "",
      refreshToken: "",
      refreshTokenEndDate: null,
    },
  }),
  methods: {
    Saveuser() {
      let thisvue = this;
      if (this.userDetail.id == 0) {
        this.$http
          .post(this.$base + "/User/", this.userDetail, {
            headers: {
              Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
            },
          })
          .then(function (response) {
            thisvue.userDetail = response.data;
            thisvue.GetuserList();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$http
          .put(this.$base + "/User/" + this.userDetail.id, this.userDetail, {
            headers: {
              Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
            },
          })
          .then(function (response) {
            thisvue.userDetail = response.data;
              thisvue.GetuserList();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    NewUser() {
      this.userDetail = {
        id: 0,
        currentCode: "",
        name: "",
        surname: "",
        email: "",
        password: "",
        usertype: "",
        refreshToken: "",
        refreshTokenEndDate: null,
      };
    },
    Getuser(id) {
      let thisvue = this;
      this.$http
        .get(this.$base + "/User/" + id, {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.userDetail = response.data;
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    GetuserList() {
      let thisvue = this;
      this.$http
        .get(this.$base + "/User/", {
          headers: {
            Authorization: `Bearer ${thisvue.Auth.auth.accessToken}`,
          },
        })
        .then(function (response) {
          thisvue.$store.commit("setUserList", response.data);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    Auth() {
      return this.$store.getters.getAuth;
    },
    UserDetailId() {
      return this.$store.getters.getUserDetailId;
    },
  },
  watch: {
    UserDetailId(id) {
      this.Getuser(id);
    },
  },
};
</script>